$primary-color: #fbbe5b;
@import 'animate.css';
@import '~loaders.css/src/animations/ball-clip-rotate-multiple.scss';


@font-face {
  font-family: 'Helvetica' ;
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'Aurore' ;
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica' ;
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}


input,
textarea {
  font-family: 'Helvetica';
  font-family: 'Aurore';
  font-family: 'Coolvetica';
}


.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut .5s .5s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
  .loader-active {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50%;
    top: 50%;
  }
}









































// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

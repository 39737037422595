.contact-form {
    width: 100%;
    margin-top: 20px;

    ul{
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='text'] {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 15px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
        
    }

    textarea {
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        max-height: 225px;
        resize: vertical;
        box-sizing: border-box;
    }

    .flat-button {

        color: #fbbe5b;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #fbbe5b;
        float: right;
        border-radius: 4px;

        &:hover {
            background: #fbbe5b;
            color: #115173;
            cursor: pointer;
        }
    }
}

.info-map {
    position: absolute;
    background-color:rgba(90,90,90,.75);
    border-radius: 20%;
    top: 50px;
    top: 7.5%;
    left: 27%;
    right: 68%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
        font-size: 16px;
        display: block;
        padding-top: 20px;
        color: #fbbe5b;

    }
}

.map-wrap {
    background: #19628b;
    float: right;
    width: 50%;
    height: 100%;
    padding-right: 3%;
    

}

.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
}

// .description {
//     font-family: 'serif';
//     font-size: 100px;
// }


@media screen and (max-width: 1200px) {
    .map-wrap {
        float: none;
        width: 100%;
        height: 400px;
        margin: 0;
    }

    .info-map {
        display: none;

    }
}
.home-page {

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
        
    }
    h1{
        color: #fbbe5b;
        font-size: 53px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;
        
        &::before {
            //content: '<h1>';
            font-family: "Aurore";
            color: #fff;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            font-family: "Aurore";
            color: #fff;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        .logo-title{
            width: 44px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;

        }
    }

    h2{
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;


    }

    .flat-button {
        color: #fbbe5b;
        font-size: 13px;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #fbbe5b;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;


        &:hover {
            background: #fbbe5b;
            color: #333 ;
            

        }

    }

}

.lottie-container {
    width: 450px;
    height: 450px;
    position: absolute;
    // top: 50%;
    // left: 70%;
    // transform: translate(150%, -70%);

    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    // .my-photo{
    //     // width: 350px;
    //     // height: 350px;
    //     border-radius: 10%;
    //     position: absolute;
    //     top: auto;
    //     right: auto;
    //     bottom: auto;
    //     left: 0;
    //     margin: auto;
    //     width: 100%;
    // }
}


@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
        font-size: 20px;
    }
    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
            // overflow-wrap: break-word;
        }
        .text-zone-second {
            font-size: 40px;
        }
        .text-zone-third {
            text-align: center;
        }
        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }

        .lottie-container {
            position: relative;
            width: 280px;
            height: auto;
            top: 50px;
            right: 0;
            box-sizing: border-box;
            margin: auto;
            left: 0;

            // svg {
            //     position: absolute;
            //     top: auto;
            //     right: auto;
            //     bottom: auto;
            //     left: 0;
            //     margin: auto;
            // }
        } 
    }
}
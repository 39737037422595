.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;    //maybe center this?
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px; //maybe center this?
        margin-top: 100px;
        // padding-left: 40%;

    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
        // animation: fadeIn 1s 1.2s; //fadeIn, fadeInUp
        // animation-fill-mode: forwards;
    


    }
    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        //width: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        // box-shadow:          //attempt at creating cards
        // 0 0 2em #808080,
        // 0 0 4em #808080;
        // margin: 4em;
        // padding: 1em;
       


        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: fill;
            // animation: backInUp 1s 1.2s;
            // animation-fill-mode: forwards;

        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.5) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;


        }
        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;

        }
        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;

        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #fbbe5b;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;

            &:hover {
                //transform: translateY(-3px);
                background: #fbbe5b;

            }
            
        }
        &:after {
            content: "";
            //background: linear-gradient(180deg, #fbbe5b, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }
            // &:hover:after {
            //     opacity: 0;
            //     //filter: blur(8px);
                
            // }
            &:hover .content {
                bottom: 0;
            }
            &:hover .portfolio-image{
                filter: blur(4px);
            }
        
    }


}


@media screen and (max-width: 1200px) {
    .portfolio-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        h1.page-title {
            margin-left: 0;
            margin-top: 0;
        }

        .image-box {
            height: 200px;
            min-width: calc(50% - 10px);
        }
        // .loader {
        //     justify-content: center;
        // }
    }
}
.nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;



    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 24px;
            height: auto;

            &.sub-logo {
                width: 50px;
            }
        }
    }
    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a{
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i{
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #fbbe5b;

                svg{
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
            &:after {
                content: '';
                font-size: 7px; //9 PX
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: 'HOME';
                }
            }
        }
        a.about-link {
            &:after {
                content: 'ABOUT';
            }
        }

        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }
        a.portfolio-link {
            &:after {
                content: 'PORTFOLIO';
            }
        }

        a.active {
            svg {
                color: #fbbe5b;
            }
        }
    }

    ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

        li{
            a{
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;

                &:hover svg {
                    color: #fbbe5b;
                }
            }
        }
    }
}

.hamburger-icon,
.close-icon {
    display: none;
}

@media screen and (max-width: 1200px) {
    .nav-bar {
        background: #181818;
        position: initial;
        height: auto;
        min-height: auto;
        width: 100%; //remove to revert
        // border-bottom: 2px solid #181818;


        .logo{
            padding-right: calc(100% - 75px);
            
        }
        ul, nav {
            display: none;

        }

        nav {
            width: 100%;
            height: 100%;
            background: #181818;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;

            a {
                display: flex;
                justify-content: left; //center
                align-items: center; //center
                top: 15px;
                left: 15px;
            }
    
            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }
            a svg {
                opacity: 1 !important;
            }

            &.mobile-show {
                display: block;
            }
        }
        .hamburger-icon,
        .close-icon {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;

        }
    }
  } 
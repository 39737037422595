// * {
//     padding: 0;
//     margin: 0;
//     box-sizing: border-box;
// }

// body {
//     width: 100%;
//     height: 100vh;
//     //background-color: rgb(32, 35, 48);

// }

// #logo {
//     position: absolute;
//     top: 50;
//     left: 50;
//     transform: translate(-50%, -50%);
// }

// #logo path:nth-child(1) {
//     stroke-dasharray: 100%;
//     stroke-dashoffset: 100%;
//     animation: line-anim 2s ease forwards;
// }

// @keyframes line-anim {
//     to {
//         stroke-dashoffset: 0;
//     }
    
// }


.logo-container {
    z-index: 0;
    width: 400px;
    height: 609px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 0;
    
        .logo{
            width: 100%;
            height: auto;
            bottom: 0;
            //transform: rotateZ(15deg) !important;
            // .logo path:nth-child(1){
            //     stroke-dasharray: 100%;
            //     stroke-dashoffset: 100%;
            //     animation: line-anim 2s ease forwards;

            // }
            stroke-dasharray: 100;
            stroke-dashoffset: 100;
            animation: line-anim 2s ease forwards;
        }
        // .solid-logo {
        //     position: absolute;
        //     top: auto;
        //     right: auto;
        //     bottom: auto;
        //     left: 0;
        //     margin: auto;
        //     width: 100%;
        //     opacity: 0;
        //     transform: rotateZ(30deg);
        //     z-index: 1;
        // }
    }
    
    .svg-container {
        stroke: #ffd700;
        stroke-width: 10px;
    }


@keyframes line-anim {
    to {
        stroke-dashoffset: 0;
    }
}